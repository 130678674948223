import DetectMobileMixin from '~/mixins/DetectMobileMixin'

export default {
  props: {
    enableOn: {
      type: Array,
      default: () => [],
    },
  },
  mixins: [DetectMobileMixin],
  data() {
    return {
      badges: [], // Array of objects => {name: <String>, html: <String>, script: <Function>}
      enable: false,
    }
  },
  watch: {
    enable(newVal) {
      if (newVal === true) {
        this.init()
      }
    },
  },
  mounted() {
    this.enable = this.enableOn.length
      ? (this.enableOn.includes('mobile') && this.isMobileDetected) ||
        ((this.enableOn.includes('tablet') && this.isTabletOrMobileDetected) ||
          (this.enableOn.includes('desktop') && !this.isMobileDetected && !this.isTabletOrMobileDetected))
      : false
  },
  methods: {
    init() {
      for (const badge of this.badges) {
        if (badge && badge.script && typeof badge.script === 'function') {
          badge.script()
        }
      }
    },
  },
}
