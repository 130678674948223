import BadgeWidgets from '~/themes/base/components/shop/BadgeWidgets'

export default {
  extends: BadgeWidgets,
  data() {
    return {
      badges: [
        {
          name: 'heureka',
          html: `<div id="showHeurekaBadgeHere-11"></div>`,
          script: () => {
            if (this.isMobileDetected || this.isTabletOrMobileDetected) {
              this.badges[0].html = `<a href="https://obchody.heureka.cz/sexshop-cz/recenze/" target="_blank" title="${this.$t(
                'BadgeWidgets.heureka.linkTitle',
              )}"><img src="/assets/images/heureka.png"></a>`
            } else {
              const HEUREKA_PUBLIC_KEY = 'A48E7AD4962249E1393984EFD1143742'
              let e = document.querySelector('head'),
                s = document.createElement('script')
              s.setAttribute('type', 'text/javascript')
              s.setAttribute('async', 'true')
              s.setAttribute('src', 'https://www.heureka.cz/direct/i/gjs.php?n=wdgt&sak=' + HEUREKA_PUBLIC_KEY)
              e.parentNode.appendChild(s)
              s.onload = function() {
                window._hwq.push(['setKey', HEUREKA_PUBLIC_KEY])
                window._hwq.push(['showWidget', '11', '1291', 'Sexshop.cz', 'sexshop-cz'])
              }
            }
          },
        },
        {
          name: 'retino',
          html: `<div class="retino-badge-container-icon" data-company="2e151749-2e8b-40f0-9416-3443f87fe1b5"></div>`,
          script: () => {
            ;(function(r, e, t, i, n, o) {
              r.RetinoBadgeQueue = r.RetinoBadgeQueue || []
              r.RetinoBadgeQueue.push({
                companyId: '2e151749-2e8b-40f0-9416-3443f87fe1b5',
                type: 'icon',
                staticUrl: 'https://cdn.retino.com/',
                mediaUrl: 'https://cdn.retino.com/media/',
                language: 'cs',
              })
              n = e.createElement(t)
              n.src = i
              n.async = true
              o = e.getElementsByTagName(t)[0]
              o.parentNode.insertBefore(n, o)
            })(window, document, 'script', 'https://cdn.retino.com/badgeInjector.js')
          },
        },
      ],
    }
  },
}
