var render, staticRenderFns
import script from "./BadgeWidgets.js?vue&type=script&lang=js&"
export * from "./BadgeWidgets.js?vue&type=script&lang=js&"
import style0 from "./BadgeWidgets.scss?vue&type=style&index=0&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./BadgeWidgets.lang.cs.yaml?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Ftmp%2Fbuild%2Fthemes%2Fsexshopcz%2Fcomponents%2Fshop%2FBadgeWidgets%2FBadgeWidgets.vue&lang=yaml"
if (typeof block0 === 'function') block0(component)

export default component.exports